import axios from 'axios'

const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization' : null
  }

export default axios.create({
 baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: false,
  headers: HEADERS,
  timeout: 1000000
 })