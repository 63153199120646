export default [
  {
    name: 'Home',
    icon: 'nc-icon nc-bank',
    path: '/overview',
    module: 'dashboard',
    action: 'view'
  },
  {
    name: 'My Paychecks',
    icon: 'nc-icon nc-bank',
    path: '/employee/paychecks',
    module: 'employee',
    action: 'view'
  },
  {
    name: 'My Company',
    icon: 'nc-icon nc-single-copy-04',
    collapsed: true,
    module: 'company',
    action: 'view',
    children: [
      {
        name: 'Employees',
        path: '/company/employees',
        module: 'employees',
        action: 'view'
      },
      {
        name: 'Departments',
        module: 'departments',
        action: 'view',
        path: '/company/departments',
      },
      {
        name: 'Positions',
        module: 'positions',
        action: 'view',
        path: '/company/positions',
      },
      {
        name: 'Process Payrolls',
        module: 'payrolls',
        action: 'view',
        path: '/company/payrolls',
      },
      {
        name: 'Payroll Reports',
        module: 'payrolls',
        action: 'view',
        path: '/company/payroll-reports',
      },
      {
        name: 'States',
        module: 'company_states',
        action: 'view',
        path: '/company/states',
      },
      {
        name: 'Earnings',
        module: 'payrolls',
        action: 'view',
        path: '/company/earnings',
      },
      {
        name: 'Deductions',
        module: 'payrolls',
        action: 'view',
        path: '/company/deductions',
      },
      {
        name: 'Jobs',
        module: 'company_jobs',
        action: 'view',
        path: '/company/jobs',
      },
      {
        name: 'Work Orders',
        module: 'work_schedules',
        action: 'view',
        path: '/company/work-schedules',
      },
      {
        name: 'Invoices',
        module: 'company_invoices',
        action: 'view',
        path: '/company/invoices',
      }
    ]
  },
  {
    name: 'Companies',
    icon: 'nc-icon nc-bank',
    path: '/companies/list',
    module: 'companies',
    action: 'view'
  },
  {
    name: 'Invoices',
    icon: 'nc-icon nc-bank',
    path: '/invoices/list',
    module: 'invoices',
    action: 'view'
  },
  {
    name: 'Tax Rates',
    icon: 'nc-icon nc-bank',
    path: '/tax-rates',
    module: 'tax_rates',
    action: 'view'
  },
  {
    name: 'Settings',
    icon: 'nc-icon nc-single-copy-04',
    collapsed: true,
    module: 'settings',
    children: [
      {
        name: 'Users',
        path: '/settings/users',
        module: 'users',
        action: 'view'
      },
      {
        name: 'Roles',
        path: '/settings/roles',
        module: 'roles',
        action: 'view'
      }
    ]
  },
]
