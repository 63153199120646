import Vue from 'vue'
import Vuex from 'vuex'

import States from '@/states.json'

import { LocalStorageService } from '@/services/LocalStorageService'
import {
  UserStatus
} from '@/Constants'

Vue.use(Vuex)
let now = new Date()
let year = now.getFullYear()

export default new Vuex.Store({
  state: {
    isInArray: function (arrayOfObjects, objectToFind) {
      return arrayOfObjects.some(obj => obj.id === objectToFind.id);
    },
    removeDuplicates: function (array, property) {
        let unique = {};
        return array.filter(obj => !unique[obj[property]] && (unique[obj[property]] = true));
    },
    years: [year+1, year, year-1, year-2, year-3,year-4,year-5,year-6],
    count: 0,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + LocalStorageService.getToken(),
    },
    multipliers: [
      { value: 0, label: 'Salary (amount)' },
      { value: 1, label: 'Hourly (hrs*1 * amount)' },
      { value: 1.5, label: 'Overtime (hrs*1.5 * amount)' },
      { value: 2, label: 'Double ( hrs*2 * amount)' },
    ],
    states: States,
    // states: LocalStorageService.getStates(),
    user: LocalStorageService.getUser(),
    userStatus: UserStatus,
    formDataHeaders: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + LocalStorageService.getToken(),
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Origin": "*"
    },
    w4_types: ['2020 or later', '2019 or earlier'],
    tax_types: ['1099', 'w2'],
    payment_methods: ['Check', "Direct Deposit", 'Dual'],
    payroll_types: ['Non-Agriculture', 'Agriculture'],
    schedule_types: ['Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly'],
    payroll_statuses: ['In Progress', 'Submitted', 'Completed'],
    payroll_status_color: ['info', 'warning', 'success'],
    earning_types: ['Non-Taxable','Taxable'],
    deduction_types: ['Others', 'Pre-Tax', '401k'],
    payroll_import_types: ['AFTER THE FACT','EMPLOYEES', 'TAXES'],
    invoice_status: ['UNPAID', 'PARTIAL PAYMENT', 'PAID'],
    invoice_status_color: ['warning','info','success'],
    federal_filing_statuses: ['Single','Married filing jointly','Married filing separately','Head of Household','Qualifying widow(er) with dependent child']
  },
  mutations: {
    saveToken: (state, token) => {
      LocalStorageService.setToken(token)
      state.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer '+token
      };
    },
    saveUser: (state, user) => {
      LocalStorageService.setUser(user)
      state.user = user;
    },
    saveStates: (state, states) => {
      LocalStorageService.setStates(states)
      state.states = states;
    },
  }
})