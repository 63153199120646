<template>
  <el-tooltip class="item" effect="dark" content="Delete" placement="top-start">
    <el-popconfirm confirm-button-text='OK' cancel-button-text='No, Thanks' icon="el-icon-info" icon-color="red" title="Are you sure about this?" @confirm="onDelete()">

    <el-button size="mini" type="danger" slot="reference" class="" :class="klass">{{ label }} <i class="fa fa-trash"></i></el-button>
    </el-popconfirm>

  </el-tooltip>

</template>

<script>
export default {
  props: {
    label: {
      type: String,
      description: 'Heading Title',
      default: null
    },
    klass: {
      type: String,
      description: 'class name',
      default: null
    },
  },
  data() {
    return {

    };
  },
  methods: {
    onDelete() {
      this.$emit('onClick')
    }
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>
