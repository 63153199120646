/* eslint-disable vue/multi-word-component-names */
/* !

  =========================================================
  * Vue Paper Dashboard 2 PRO - v2.4.1
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2022 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/

import Vue from 'vue';
import './pollyfills';
import VueRouter from 'vue-router';
// import VueNotify from 'vue-notifyjs';
import Notifications from 'vue-notification'

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import App from './App.vue';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import SideBar from './components/UIComponents/SidebarPlugin';
import initProgress from './progressbar';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)

import {
  Table, TableColumn, Tooltip, Upload, Autocomplete,
  Image, Collapse, Row, Button, CollapseItem, Input, Link, Card, Tag, Alert,
  Descriptions, DescriptionsItem, Select, Option, Dialog, Timeline, TimelineItem,
  Drawer, RadioGroup, RadioButton, Radio, Checkbox, Badge, DatePicker, Tabs, TabPane,
  Carousel, CarouselItem, InputNumber, PageHeader, Popconfirm, Popover, Switch, Form, FormItem,
  Menu, MenuItem, Submenu, Avatar
} from 'element-ui'

Vue.use(Avatar)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Switch)
Vue.use(Popover)
Vue.use(Popconfirm)
Vue.use(PageHeader)
Vue.use(InputNumber)
Vue.use(CarouselItem)
Vue.use(Carousel)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
Vue.use(Upload)
Vue.use(Autocomplete)
Vue.use(Image)
Vue.use(Collapse)
Vue.use(Row)
Vue.use(Button)
Vue.use(CollapseItem)
Vue.use(Input)
Vue.use(Link)
Vue.use(Card)
Vue.use(Tag)
Vue.use(Alert)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Drawer)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Badge)
Vue.use(DatePicker)

// router setup
import routes from './routes/routes';

// library imports

import './assets/sass/paper-dashboard.scss';
import './assets/sass/demo.scss';

import './assets/sass/custom.scss';
import './assets/sass/skill-card.scss';

import sidebarLinks from './sidebarLinks';
import './registerServiceWorker';
// plugin setup
Vue.use(VueRouter);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);

// Vue.use(VueNotify);
// Vue.use(VueNotify, {type: 'primary', timeout: 500000});
Vue.use(Notifications)
Vue.use(SideBar, { sidebarLinks: sidebarLinks });

import Loading from './components/UIComponents/Loading';
import Background from './components/UIComponents/Background';
import PPagination from 'src/components/UIComponents/Pagination.vue'
import Header from './components/Common/PageHeader.vue'
import ConfirmDelete from './components/Common/ConfirmDelete.vue'
Vue.component('loading', Loading);
Vue.component('background', Background);
Vue.component('p-pagination', PPagination);
Vue.component('page-header', Header)
Vue.component('confirm-delete'. ConfirmDelete)

import store from './store';

locale.use(lang);

import helpers from './Mixins/Helper'

// example  <div v-allowed="'dashboard, view'"></div>
Vue.directive('allowed', {
  bind: function (el, binding, vnode, prevVnode) {
    let list =  binding.value.split(",");
    if (!helpers.isAllowed(list[0].replace(/\s/g, ''), list[1].replace(/\s/g, ''))) el.style.display =  'none'
  }
})

Vue.mixin({
  data: function() {
    return {
      isAllowed(module, action) {
        return helpers.isAllowed(module, action)
      },
      hours() {
        return helpers.hours()
      },
      getTimeSlotsFromHours(hours) {
        return helpers.getTimeSlotsFromHours(hours)
      },
      money(amount, decimals) {
        return helpers.toCurrency(amount, decimals)
      }
    }
  }
})

let loading = Vue.observable({loading: false})
Object.defineProperty(Vue.prototype, '$loading', {
  get() {
    return loading.loading
  },
  set(value) {
    loading.loading = value
  }
})

let loadingText = Vue.observable({loadingText: 'Loading.....'})
Object.defineProperty(Vue.prototype, '$loadingText', {
  get() {
    return loadingText.loadingText
  },
  set(value) {
    loadingText.loadingText = value
  }
})

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash};
    } else {
      return {x: 0, y: 0};
    }
  },
});


import moment from 'moment';
import moment_timezone from 'moment-timezone'
import VueMoment from 'vue-moment';
// moment.locale('es');
// moment.tz('UTC')

Vue.use(VueMoment, { moment });

import api from '@/resources/axios';

api.interceptors.response.use(
    (response) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    (error) => {
      if (error.response.status) {
        if (error.response.data.message) {
          let name = router.currentRoute.name
          if (error.response.data.message == 'Unauthenticated.') {

            if (name != "Login") {
              alert('Please login to proceed');
              router.replace({
                path: '/login',
                query: {redirect: router.currentRoute.fullPath},
              });
            }
           
            throw new axios.Cancel('Operation canceled by the user.');
          }
        }
        if (error.response.status === 500) {
          Vue.prototype.$errors = error.response.data
        }
        if (error.response.status === 400) {
          Vue.prototype.$errors = error.response.data
        
        }
        return Promise.reject(error);
      }
    },
);

initProgress(router);

const errors = Vue.observable({ errors: {} })

Object.defineProperty(Vue.prototype, '$errors', {
  get () {
    return errors.errors
  },
  set (value) {
    errors.errors = value
  }
})


import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import { mask } from 'vue-the-mask'
Vue.directive('mask', function (el, binding) {
  if (!binding.value) {
    return
  }
  mask(el, binding)
})


/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  render: (h) => h(App),
  router,
  watch: {
    $loading() {
      if (!this.$loading) this.$loadingText = "Loading..."
    },
    $errors () {
      let errors = this.$errors
      if (errors.message) {
        this.$notify({
          type: 'danger',
          title: "Error",
          text: errors.message,
        });
      } else {
        for (var i in errors) {
          console.log(errors[i])
          if (Array.isArray(errors[i])) {
            errors[i].forEach(message => {
              this.$notify({
                type: 'danger',
                title: "Error",
                text: message,
              });
            });
          } else {
            for (var j in errors[i]) {
              if (Array.isArray(errors[i][j])) {
                 errors[i][j].forEach(message => {
                  this.$notify({
                    type: 'danger',
                    title: "Error",
                    text: message,
                  });
                });
              }
            }
          }
        }
      }
      
    }
  }
});
