import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Logout from 'src/components/Dashboard/Views/Pages/Logout.vue'

const Signup = () => import(/* webpackChunkName: "Sigunup" */ 'src/components/System/Signup/Signup.vue');

const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ 'src/components/System/ForgotPassword/ForgotPassword.vue');

const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ 'src/components/System/ResetPassword/ResetPassword.vue');

import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

//Settings pages
const Users = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Users/UsersList.vue');
const UserForm = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Users/UserForm.vue');

const Roles = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Roles/RolesList.vue');


const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ 'src/components/System/Profile/UserProfile.vue');


//companies
const CompaniesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Companies/CompaniesList.vue');


const EmployeesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Employees/EmployeesList.vue');

const DepartmentsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Departments/DepartmentsList.vue');
const PositionsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Positions/PositionsList.vue');


const PayrollGroupsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Payrolls/PayrollGroupList.vue');
const PayrollsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Payrolls/PayrollsList.vue');
const PaychecksList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Payrolls/Paychecks/PaychecksList.vue');
const AllPayrollReportsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Payrolls/Reports/AllPayrollReportsList.vue');


const CompanyStatesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/States/StatesList.vue');

const TaxRatesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/TaxRates/TaxRatesList.vue');

const CompanyEarningsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Earnings/CompanyEarningsList.vue');
const CompanyDeductionsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Deductions/CompanyDeductionsList.vue');


const EmployeePaychecksList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Employee/Paychecks/EmployeePaychecksList.vue');

const InvoicesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Invoices/InvoicesList.vue');

const WorkSchedulesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/WorkSchedules/WorkSchedulesList.vue');

const CompanyJobsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Jobs/CompanyJobsList.vue');
const CompanyInvoicesList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Invoices/CompanyInvoicesList.vue');

const PayrollImportsList = () => import(/* webpackChunkName: "Companies" */ 'src/components/System/Company/Payrolls/PayrollImports/PayrollImportsList.vue');

// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')


let SettingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings',
  children: [
    {
      path: 'users',
      name: 'Users',
      component: Users
    },
    {
      path: 'users/:id/edit',
      name: 'UserEdit',
      component: UserForm
    },
    {
      path: 'roles',
      name: 'Roles',
      component: Roles
    },
 ]
}

let UserProfileMenu = {
  path: '/my-profile',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'UserProfile',
      component: UserProfile
    },
 ]
}

let TaxRateMenu = {
  path: '/tax-rates',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'TaxRatesList',
      component: TaxRatesList
    },
 ]
}

let CompaniesMenu = {
  path: '/companies',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'list',
      name: 'CompaniesList',
      component: CompaniesList
    },
 ]
}

let InvoicesMenu = {
  path: '/invoices',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'list',
      name: 'InvoicesList',
      component: InvoicesList
    },
 ]
}


let EmployeeMenu = {
  path: '/employee',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'paychecks',
      name: 'EmployeePaychecksList',
      component: EmployeePaychecksList
    },
 ]
}

let CompanyMenu = {
  path: '/company',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'employees',
      name: 'EmployeesList',
      component: EmployeesList
    },
    {
      path: 'departments',
      name: 'DepartmentsList',
      component: DepartmentsList
    },
    {
      path: 'positions',
      name: 'PositionsList',
      component: PositionsList
    },
    {
      path: 'payrolls',
      name: 'PayrollGroupsList',
      component: PayrollGroupsList
    },
    {
      path: 'payroll-reports',
      name: 'AllPayrollReportsList',
      component: AllPayrollReportsList
    },
    {
      path: 'payroll-imports',
      name: 'PayrollImportsList',
      component: PayrollImportsList
    },
    {
      path: 'payrolls/:id/list',
      name: 'PayrollsList',
      component: PayrollsList
    },
    {
      path: 'payrolls/:id/list/:payroll_id/paychecks',
      name: 'PaychecksList',
      component: PaychecksList
    },
    {
      path: 'states',
      name: 'CompanyStatesList',
      component: CompanyStatesList
    },
    {
      path: 'earnings',
      name: 'CompanyEarningsList',
      component: CompanyEarningsList
    },
    {
      path: 'deductions',
      name: 'CompanyDeductionsList',
      component: CompanyDeductionsList
    },
    {
      path: 'work-schedules',
      name: 'WorkSchedulesList',
      component: WorkSchedulesList
    },
    {
      path: 'jobs',
      name: 'CompanyJobsList',
      component: CompanyJobsList
    },
    {
      path: 'invoices',
      name: 'CompanyInvoicesList',
      component: CompanyInvoicesList
    },
 ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let AccountMenu = {
  path: '/account',
  component: DashboardLayout,
  redirect: '/account/info',
  children: [
    {
      path: 'info',
      name: 'UserInfo',
      component: User
    },
  ]
}

let logoutMenu = {
  path: '/logout',
  component: DashboardLayout,
  redirect: '/logout/user',
  children: [
    {
      path: 'user',
      name: 'Logout',
      component: Logout
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Signup
}

let ForgotPasswordPage = {
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: ForgotPassword
}

let resetPasswordPage = {
  path: '/reset-password',
  name: 'ResetPassword',
  component: ResetPassword
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  AccountMenu,
  TaxRateMenu,
  EmployeeMenu,
  UserProfileMenu,
  CompaniesMenu,
  InvoicesMenu,
  CompanyMenu,
  SettingsMenu,
  pagesMenu,
  loginPage,
  logoutMenu,
  registerPage,
  ForgotPasswordPage,
  resetPasswordPage,
  lockPage,
  {
    path: '',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
