<template>
<drop-down icon="nc-icon nc-bell-55" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
  <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" :aria-expanded="isOpen" @click="openNotification()">
    <el-badge :value="unreadNotifications" class="item" :hidden="!unreadNotifications">
      <i class="nc-icon nc-bell-55"></i>
    </el-badge>
  </a>
  <a class="dropdown-item" style="width: 350px;    overflow: hidden;" v-for="notification in notifications" :key="notification.id" @click="goTo(notification)" :class="notification.seen ? '':'unseen'">
    <div class="title">{{ notification.title }}</div>
    <div class="body" style="font-size: 11px;word-wrap: break-word;white-space: pre-line;">{{ notification.body }}</div>
    <div style="font-style:italic;font-size:10px">{{ getTime(notification.timestamp) }}</div>
  </a>

  <a class="dropdown-item" href="#" v-if="notifications.length">
    <el-button type="text" @click="clearNotifications()">Clear all</el-button>
  </a>

  <a class="dropdown-item" href="#" v-if="!notifications.length">
    <div class="title">No notifications at the moment</div>
  </a>
</drop-down>
</template>

<script>
import {
  mapState
} from 'vuex';
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import {
  User
} from '@/resources/user'
import {
  db
} from '@/firebase'

export default {
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      unreadNotifications: 0,
      notifications: [],
      doneInitFirebase: false
    }
  },
  created: function () {
    if (!this.doneInitFirebase) {
      this.initialiazeFirebase()
    }
    this.getNotifications()
  },
  methods: {
    countUnseenNotifications() {
      let path = 'notifications/' + this.user.name

      db.ref(path)
      .orderByChild("seen")
      .equalTo(false)
      .on("value", (snapshot) => {
        const count = snapshot.numChildren();
        this.unreadNotifications = count
      });
    },
    goTo(notification) {
      let ref = db.ref('notifications/' + this.user.name + '/' + notification.id)
      notification.seen = true

      if (!notification.link) notification.link = ''
      ref.set(notification)

      if (notification.link && notification.link !== this.$route.path) {
        this.$router.push(notification.link)
      }
    },
    openNotification() {
      this.unreadNotifications = 0
    },
    initialiazeFirebase() {
      let self = this
      const messaging = firebase.messaging()
      let firebaseKeyPair = 'BJFp4RZ2u-5bV2WlzSp0FWz7iMGuM7YRsaCsGR-rLW2sb3NWisu9tD-Ii-T-DskWEhiyIORzpU5Yl716L2oaGJw'
      messaging.getToken({
        vapidKey: firebaseKeyPair
      }).then((currentToken) => {
        if (currentToken) {
          self.updateUserFcm(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      }).finally(() => {
        this.doneInitFirebase = true
      });

      messaging.onMessage(function (payload) {
        console.log('Receiving foreground message');
        self.showNotification(payload.notification, payload.data)
      });

    },
    showNotification(notification, data) {
      this.$notify({
        type: 'success',
        title: notification.title,
        text: notification.body,
      });
    },
    getNotifications() {
      var self = this
      if (!this.user) return false
      let path = 'notifications/' + this.user.name

      this.countUnseenNotifications()
      var ref = db.ref(path)

      ref
        .orderByChild('timestamp')
        .limitToLast(10) // Adjust the limit as needed
        .on("value", (snapshot) => {
          const data = snapshot.val();
          self.notifications = []
          for (var i in data) {
            self.addNotificationFromFirebase(i, data[i])
          }

        });

  
      ref
      .orderByChild('timestamp')
      .limitToLast(1) // Limit to the last record (the latest added)
      .on("child_added", (snapshot) => {
        const notification = snapshot.val();
        var notificationDate = new Date(notification.timestamp * 1000)
        var currentDate = new Date()
        const differenceSec = Math.abs(currentDate - notificationDate) / 1000;
        console.log("New notification")
        if (differenceSec < 3) this.showNotification(notification)

      });
    },
    addNotificationFromFirebase(id, notification) {
      var seen = false
      if (notification.seen) seen = notification.seen
      var obj = {
        id: id,
        user_name: notification.user_name,
        title: notification.title,
        body: notification.body,
        link: notification.link,
        timestamp: notification.timestamp,
        seen: seen
      }
      this.notifications.push(obj)
      this.notifications = this.notifications.sort(function (a, b) {
        return b.timestamp - a.timestamp
      })
    },
    getTime(timestamp) {
      return this.$moment.unix(timestamp).fromNow()
    },
    clearNotifications() {
      let ref = db.ref('notifications/' + this.user.name)
      ref.remove();
    },
    updateUserFcm(token) {
      console.log("FCM TOKEN " + token)
      User.updateFcm({
          device_key: token
        })
        .then(result => {})
        .catch(() => {})
    }
  }
}
</script>
<style>
.unseen {
  background: #a2a2a3;
    color: #fff !important;
}
</style>
