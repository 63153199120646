<template>
  <nav :class="classes" class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <slot></slot>
      <slot name="toggle-button">
        <button aria-controls="navigation-index"
                @click="toggleMenu"
                aria-expanded="true" aria-label="Toggle navigation"
                class="navbar-toggler" data-toggle="collapse" type="button"><span
          class="navbar-toggler-bar navbar-kebab"></span><span class="navbar-toggler-bar navbar-kebab"></span><span
          class="navbar-toggler-bar navbar-kebab"></span></button>
          
      </slot>
      <CollapseTransition>
        <div class="collapse navbar-collapse justify-content-end show"
             :class="navbarMenuClasses"
             v-show="showNavbar"
             id="navigation">
          <ul class="navbar-nav">
            <slot name="navbar-menu"></slot>
          </ul>
        </div>
      </CollapseTransition>

      <div v-if="user && user.company" class="bill-alert">
        <!-- <el-alert
            v-if="shouldShowExpireAlert()"
            type="error"
            effect="dark"
            show-icon>
             <slot name="title" v-if="!user.company.company_plan.is_cancel">
                <div>{{ 'Your Mailbox subscription has already expired.' }} 
                  <el-link type="info" href="/subscription/renew">Click here</el-link>  if you wish to renew the service
                </div>
             </slot>
             <slot name="title" v-if="user.company.company_plan.is_cancel">
                  <div>You have cancelled your Mailbox subscription.
                    <el-link type="info" href="/subscription/renew">Click here</el-link>  if you wish to reactivate the service
                  </div>
               </slot>
          </el-alert> -->
      </div>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
import { User } from '@/resources/user'
import { State } from '@/resources/state'

  export default {
    name: 'navbar',
    props: {
      showNavbar: {
        type: Boolean,
        description: "Whether navbar is visible"
      },
      navbarMenuClasses: {
        type: [String, Object],
        description: 'Navbar menu css classes'
      },
      transparent: {
        type: Boolean,
        default: true,
        description: 'Whether navbar is transparent'
      },
      position: {
        type: String,
        default: 'absolute',
        description: 'Navbar position (absolute|fixed|relative)'
      },
      type: {
        type: String,
        default: 'white',
        validator(value) {
          return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
        },
        description: 'Navbar type (primary|info|danger|default|warning|success)'
      }
    },
    model: {
      prop: 'showNavbar',
      event: 'change'
    },
    components: {
      CollapseTransition
    },
    computed: {
      classes() {
        let color = `bg-${this.type}`;
        let navPosition = `navbar-${this.position}`;
        return [
          { 'navbar-transparent': !this.showNavbar && this.transparent },
          { [color]: this.showNavbar || !this.transparent },
          navPosition]
      }
  },
  data() {
    return {
      user: null,
      company: null
    };
  },
    watch:{
      $route (to, from){
        this.checkCredential()
      },
      user() {
        
      }
    },
    created: function() {
      this.checkCredential()
      this.checkStates()
    },
  methods: {
      shouldShowExpireAlert() {
        return  this.$route.name != 'RenewSubscription' && this.user.company.minutes_before_due_date <= 0
      },
      checkCredential() {
        let publicRoutes = ['Login', 'Register', 'ResetPassword', 'ForgotPassword']
        if (!publicRoutes.includes(this.$route.name)) {
          if (!this.$store.state.user) {
            alert("You have to login again")
            this.$router.push({ name: 'Login' , query: {redirect: this.$route.path }})
          } else {
            this.user = this.$store.state.user
            if (this.$route.name == 'Overview') {
              this.refreshCompany()
            }
          }
        }
    },
      refreshCompany() {
        User.settings()
          .then(result => {
            this.user = result.data.user
            this.user.permissions = result.data.permissions
            
            this.$store.commit('saveUser', this.user);
          })
          .catch(error => {
            
          }).finally(() => {
            this.loading = false
          })
      },
      toggleMenu() {
        this.$emit('change', !this.showNavbar);
    },
    checkStates() {
      let states = this.$store.state.states
      if (states) return true

      this.$loading = true
      State.get()
        .then(result => {
          this.$store.commit('saveStates', result.data.states);
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
      }
    }
  }
</script>
<style scoped>
  .navbar-relative {
    position: relative;
  }
</style>
